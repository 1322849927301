import jwtDecode from 'jwt-decode';
import { IClaims, IUser } from '../types/user';
import { Action } from './actions';
import { isJwtExpired } from '../utils/is-jwt-expired';

export type DataAccessState = {
  error: string | undefined;
  user: IUser;
};

export const initialState: DataAccessState = {
  error: undefined,
  user: {
    email: '',
    token: '',
    roles: [],
    legacyToken: '',
    clientID: '',
    customerID: '',
    personID: '',
    tokenExpiration: undefined,
  },
};

export function reducer(
  state: DataAccessState,
  action: Action
): DataAccessState {
  switch (action.type) {
    case 'Set auth': {
      const token = jwtDecode<IClaims>(action.payload.JWT);
      if (isJwtExpired(action.payload.JWT)) {
        console.error('JWT expired');
        // FIXME add Sentry logging
        // throw new Error('JWT expired');
      }

      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload.JWT,
          email: token.Email ?? action.payload.Email ?? state.user.email,
          clientID: token.ClientID,
          // customerID:
          //   token.CustomerID ??
          //   action.payload.CustomerID ??
          //   state.user.customerID,
          customerID: token.CustomerID ?? action.payload.CustomerID,
          roles: token.Roles,
          tokenExpiration: token.exp,
          // personID: action.payload.PersonID ?? state.user.personID,
          personID: action.payload.PersonID,
        },
      };
    }
    case 'Set legacy auth': {
      return {
        ...state,
        user: {
          ...state.user,
          legacyToken: action.payload.legacyToken,
        },
      };
    }
    case 'Unset auth': {
      return {
        ...state,
        user: { ...initialState.user },
      };
    }
    case 'Set error':
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
