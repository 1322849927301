import { A } from '@axo/deprecated/util/ui-components';
import { termsAndConditionsUrl } from '@axo/insurance/const/fi';
import { NavWrapper, Spinner, StepNavigator } from '@axo/insurance/ui';
import { EventCode, useEventLogger } from '@axo/shared/data-access/event-log';
import { useInsurancePayment } from '@axo/shared/data-access/hooks';
import { event_log, insurance_payment } from '@axo/shared/data-access/types';
import { useTranslation } from '@axo/shared/i18n';
import { classNames } from '@axo/shared/util/dom';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { IPayEx } from '../../../StepProvider/steps/VerifyPaymentCard/IPayEx';
import styles from './VerifyPaymentCard.module.scss';

declare global {
  interface Window {
    payex: IPayEx | undefined;
  }
}

function VerifyPaymentEmbed({
  payment,
  onCompletePayment,
  onRetry,
}: {
  payment: insurance_payment.Payment;
  onCompletePayment: () => void;
  onRetry: () => void;
}) {
  const [pollingStart, setPollingStart] = useState(new Date());
  useEffect(() => setPollingStart(new Date()), [payment.ID]);
  const { t } = useTranslation();
  const log = useEventLogger();

  const embedUrl = payment.EmbedURL;
  const tag = useRef<HTMLScriptElement | null>();

  useEffect(() => {
    function onLoad() {
      const paymentTag: event_log.Resource = {
        Type: 'insurance_payment/Payment',
        ID: payment.ID,
      };
      window.payex?.hostedView
        .checkout({
          container: {
            checkout: 'checkout-container',
          },
          culture: 'fi-FI',
          onPaid(event) {
            log(
              EventCode.PayExEvent,
              { name: event.event, paymentOrderID: event.paymentOrder.id },
              paymentTag
            );
            onCompletePayment();
          },
          onEventNotification(event) {
            log(
              EventCode.PayExEvent,
              {
                name: event.event,
                paymentOrderID: event.paymentOrder.id,
                sourceEvent: event.sourceEvent,
              },
              paymentTag
            );
          },
        })
        .open();
    }

    if (embedUrl && !tag.current) {
      const script = document.createElement('script');
      script.src = embedUrl;
      //script.async = true;
      script.onload = onLoad;
      document.head.appendChild(script);

      tag.current = script;
    }
  }, [embedUrl, log, onCompletePayment, payment]);

  if (!embedUrl) {
    const hasTimedOut =
      +new Date() - +pollingStart > 1_000 * 60; /* one minute */
    if (hasTimedOut) {
      return <VerifyFailed onRetry={onRetry} />;
    }
    return <Spinner />;
  }

  return (
    <div className={classNames(styles.verifyPaymentEmbedContainer)}>
      <div id="checkout-container"></div>
      <A href={termsAndConditionsUrl} target="_blank" rel="noreferrer">
        {t('Terms and Conditions')}
      </A>
    </div>
  );
}

function ErrorView({
  message,
  onRetry,
}: {
  message: ReactNode;
  onRetry: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <p>{message}</p>
      <button onClick={onRetry}>{t('Retry')}</button>
      <p>{t('Call customer service if the problem persists')}</p>
    </div>
  );
}

function VerifyFailed({ onRetry }: { onRetry: () => void }) {
  const { t } = useTranslation();
  return <ErrorView message={t('Something went wrong')} onRetry={onRetry} />;
}

function VerifyPayment({
  id,
  isComplete,
  onCreatePayment,
  onCompletePayment,
  onRetry,
}: {
  id: string;
  isComplete: boolean;
  onCreatePayment: (payment: insurance_payment.Payment) => void;
  onCompletePayment: () => void;
  onRetry: () => void;
}) {
  const payment = useInsurancePayment(id, isComplete);
  const isFailed = payment.data?.Status === insurance_payment.Status.Failed;
  const isPending = payment.data?.Status === insurance_payment.Status.Pending;
  const hasEmbed = !!payment.data?.EmbedURL;
  const TIMEOUT_MS = 60_000; // 1 minute
  const isOld = payment.data
    ? +new Date() - +new Date(payment.data.CreatedAt) > TIMEOUT_MS
    : false;
  const hasTimedOut = !hasEmbed && isOld;

  useEffect(() => {
    (function updateStateUntilNotPending() {
      if (payment.data && !isPending) {
        onCreatePayment(payment.data);
      }
    })();
  }, [isPending, onCreatePayment, payment.data]);

  if (!payment.data) {
    return <Spinner />;
  }

  if (isComplete && isPending) {
    return <Spinner />;
  }

  if (hasTimedOut || isFailed) {
    return <VerifyFailed onRetry={onRetry} />;
  }

  return (
    <VerifyPaymentEmbed
      payment={payment.data}
      onCompletePayment={onCompletePayment}
      onRetry={onRetry}
    />
  );
}

function Nav({
  paymentStatus,
  isEmbedded,
  onNext,
  onBack,
}: {
  paymentStatus: insurance_payment.Status | undefined;
  isEmbedded: boolean | undefined;
  onNext: () => void;
  onBack: () => void;
}) {
  const { t } = useTranslation();

  function handleNext() {
    onNext();
  }

  function handleBack() {
    onBack();
  }

  const isCompleted = paymentStatus === insurance_payment.Status.Completed;
  const isFailed = paymentStatus === insurance_payment.Status.Failed;
  const isPending = paymentStatus === insurance_payment.Status.Pending;

  return (
    <NavWrapper start alwaysStickyNavButtons={isEmbedded}>
      {isCompleted && (
        <StepNavigator
          rightButton={{
            width: 'full-width',
            text: t('Next') as string,
            variant: 'enabled',
            onClick: handleNext,
          }}
        />
      )}
      {(isFailed || isPending) && (
        <StepNavigator
          leftButton={{
            width: 'full-width',
            variant: 'secondary',
            onClick: handleBack,
            text: t('Go back') as string,
          }}
        />
      )}
    </NavWrapper>
  );
}

export function VerifyPaymentCard({
  payment,
  isEmbedded,
  isComplete,
  onNext,
  onBack,
  onCreatePayment,
  onCompletePayment,
  onRetry,
}: {
  payment: insurance_payment.Payment;
  isEmbedded?: boolean;
  isComplete: boolean;
  onNext: () => void;
  onBack: () => void;
  onCreatePayment: (payment: insurance_payment.Payment) => void;
  onCompletePayment: () => void;
  onRetry: () => void;
}) {
  return (
    <>
      {payment ? (
        <VerifyPayment
          id={payment.ID}
          isComplete={isComplete}
          onCreatePayment={onCreatePayment}
          onCompletePayment={onCompletePayment}
          onRetry={onRetry}
        />
      ) : null}
      <Nav
        paymentStatus={payment?.Status}
        isEmbedded={isEmbedded}
        onNext={onNext}
        onBack={onBack}
      />
    </>
  );
}
