import {
  A,
  Hr,
  Icons,
  Slider,
  Tooltip,
} from '@axo/deprecated/util/ui-components';
import {
  payoutAndPremium,
  prePurchaseInformationUrl,
  productFactSheetUrl,
} from '@axo/insurance/const/fi';
import {
  InsuranceFeatureFlags,
  InsuranceFeatureFlagsValues,
  StepContext,
  usePayoutAndPremium,
} from '@axo/insurance/feature/providers';
import {
  ContentBoxWrapper,
  Drawer,
  NavWrapper,
  StepNavigator,
} from '@axo/insurance/ui';
import {
  EventCode,
  useEventField,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import { useTranslation } from '@axo/shared/i18n';
import { useAnalytics } from '@axo/shared/services/analytics';
import {
  useFlagImpression,
  useFlags,
} from '@axo/shared/services/feature-flags';
import { IconType } from '@axo/shared/ui/atoms';
import { CheckList } from '@axo/shared/ui/core';
import { classNames } from '@axo/shared/util/dom';
import { currencyFormatter } from '@axo/shared/util/formatters';
import { debounceFunction } from '@axo/shared/util/fp';
import { useContext, useEffect, useMemo, useRef } from 'react';
import stepStyles from '../../StepProvider.module.scss';
import { PersonDataForm } from './PersonDataForm';
import styles from './SelectCoverage.module.scss';
import { usePersonDataForm } from './usePersonDataForm';

export const SelectCoverage = () => {
  const { dispatch, state, isEmbedded } = useContext(StepContext);
  const { t } = useTranslation();
  const {
    isFormResolved,
    submitPersonData,
    handleInputChange,
    incrementContinueAttemptCounter,
    formData,
    continueAttempts,
  } = usePersonDataForm();
  const ref = useRef<HTMLDivElement>(null);
  const { track } = useAnalytics();
  const {
    steps: { selectCoverage: stepData },
  } = state;
  const { getPayoutAndPremiumItem } = usePayoutAndPremium('fi');
  let { flag_ins_coverage_default_amount_fi, flag_ins_coverage_show_legend } =
    useFlags(InsuranceFeatureFlags) as InsuranceFeatureFlagsValues;
  useFlagImpression(InsuranceFeatureFlags, {
    flag_ins_coverage_default_amount_fi,
    flag_ins_coverage_show_legend,
  });
  flag_ins_coverage_default_amount_fi ??= 500;
  flag_ins_coverage_show_legend ??= false;
  useEffect(() => {
    if (stepData.insurance.payout !== 0) return;

    dispatch({
      type: 'Set step data',
      scope: { parentType: 'selectCoverage' },
      payload: {
        ...stepData,
        insurance: getPayoutAndPremiumItem(flag_ins_coverage_default_amount_fi),
      },
    });
  }, [
    dispatch,
    flag_ins_coverage_default_amount_fi,
    getPayoutAndPremiumItem,
    stepData,
  ]);

  const log = useEventLogger();
  useEventField('current-step', 'select-coverage');
  useEventField('payout', stepData.insurance.payout.toString());
  useEventField('premium', stepData.insurance.premium.toString());

  async function handleAcceptInsurance() {
    incrementContinueAttemptCounter();
    if (!isFormResolved) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    submitPersonData();
    log(EventCode.InsuranceNext);
    dispatch({
      type: 'Set step data',
      scope: { parentType: 'selectCoverage' },
      payload: { state: 'completed' },
    });
    track({
      event: `Insurance Step ${stepData.name} Completed`,
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'terms',
    });
  }

  function handleDeclineInsurance() {
    log(EventCode.InsuranceBack);
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'touched' },
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'explainer',
    });
  }

  const logSelectCoverage = useMemo(
    () =>
      debounceFunction((newPayout: number) => {
        log(EventCode.SelectCoverage);
        track({
          event: 'Insurance Coverage Amount Changed',
          params: {
            payout: newPayout,
          },
        });
      }),
    [log, track]
  );

  return (
    <>
      <ContentBoxWrapper isEmbedded={isEmbedded}>
        <div className={styles.insuranceSummaryContainer}>
          <p className={styles.insuranceLabel}>
            {t('Choose your insurance level')}
          </p>
          <div className={styles.insuranceSummary}>
            <div
              className={classNames(styles.insuranceSummaryItem, styles.payout)}
            >
              <div className={styles.payoutLabel}>
                <p className={styles.label}>
                  {t('Payable in the event of damage')}
                </p>
                <Tooltip
                  text={t(
                    'You will start receiving the compensation once the insurance is activated'
                  )}
                  classes={{ text: styles.tooltipText }}
                >
                  <div className={styles.iconContainer}>
                    <Icons.CircleInfoOutline
                      size="xs"
                      color={{ primary: '--info-500' }}
                    />
                  </div>
                </Tooltip>
              </div>
              <p className={styles.value}>
                {currencyFormatter(stepData.insurance.payout)}/{t('Month')}
              </p>
            </div>
            <div
              className={classNames(
                styles.insuranceSummaryItem,
                styles.rightAlign
              )}
            >
              <p className={styles.label}>{t('Monthly cost')}</p>
              <p className={styles.value}>
                {currencyFormatter(stepData.insurance?.premium)}/{t('Month')}
              </p>
            </div>
          </div>
        </div>

        <div className={styles.insuranceSlider}>
          <Slider
            hideLegend={!flag_ins_coverage_show_legend}
            min={payoutAndPremium[1].payout}
            max={payoutAndPremium[payoutAndPremium.length - 1].payout}
            step={100}
            value={stepData.insurance.payout}
            onChange={(value) => {
              dispatch({
                type: 'Set step data',
                scope: { parentType: 'selectCoverage' },
                payload: {
                  ...stepData,
                  insurance: getPayoutAndPremiumItem(value),
                },
              });
              logSelectCoverage(value);
            }}
          />
        </div>
        <Hr className={stepStyles.horizontalSeparator} />
        <Drawer
          label={t(
            'If you are not sure of the amount of insurance, click here'
          )}
          details={
            <p className={styles.drawerDetails}>
              {t('Add together the monthly cost of your economy')}
            </p>
          }
        ></Drawer>
        <Hr className={stepStyles.horizontalSeparator} />
        <Drawer
          label={t('The benefits of insurance')}
          details={
            <CheckList className={styles.checklist}>
              <CheckList.Item
                iconType={IconType.Circle}
                key="B"
                label={t(
                  'You decide for yourself where you are using, for example, for rent, loan costs, heating or food'
                )}
                state="valid"
              />
              <CheckList.Item
                iconType={IconType.Circle}
                key="C"
                label={t('Your insurance will follow you, not your loan')}
                state="valid"
              />
            </CheckList>
          }
        ></Drawer>
        <Hr className={stepStyles.horizontalSeparator} />
        <div className={styles.extraInfo}>
          <p className={styles.label}>
            {t('Detailed information on insurance')}
          </p>
          <div className={styles.extraInfoLinkContainer}>
            <div className={styles.extraInfoLink}>
              <Icons.Pdf size="sm" />
              <A
                href={productFactSheetUrl}
                target="_blank"
                rel="noreferrer"
                onClick={() => log(EventCode.OpenProductFactSheet)}
              >
                {t('Product Fact Sheet')}
              </A>
            </div>
            <div className={styles.extraInfoLink}>
              <Icons.Pdf size="sm" />
              <A
                href={prePurchaseInformationUrl}
                target="_blank"
                rel="noreferrer"
                onClick={() => log(EventCode.OpenPrePurchaseInformation)}
              >
                {t('Pre-purchase Information')}
              </A>
            </div>
          </div>
        </div>
        <PersonDataForm
          formData={formData}
          continueAttempts={continueAttempts}
          handleInputChange={handleInputChange}
          ref={ref}
        />
      </ContentBoxWrapper>
      <NavWrapper alwaysStickyNavButtons={isEmbedded}>
        <p className={classNames(styles.label, styles.navButtonsLabel)}>
          {t('Do you want to take insurance')}
        </p>
        <StepNavigator
          leftButton={{
            width: 'standard',
            text: t('No, move forward without insurance'),
            variant: 'back',
            onClick: () => handleDeclineInsurance(),
          }}
          rightButton={{
            width: 'standard',
            text: t('Yes, continue'),
            variant: isFormResolved ? 'enabled' : 'disabled',
            onClick: () => handleAcceptInsurance(),
          }}
        />
      </NavWrapper>
    </>
  );
};
