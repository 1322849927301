import { useContext, useEffect } from 'react';
import {
  KnownResourceType,
  useEventResource,
} from '@axo/shared/data-access/event-log';
import { insurance, insurance_request } from '@axo/shared/data-access/types';
import {
  useCreateInsuranceRequest,
  useInsuranceRequest,
} from '@axo/shared/data-access/hooks';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import {
  InsuranceContext,
  useInsuranceSourceTag,
} from '@axo/insurance/feature/providers';

type ProvideInsuranceProps = {
  shouldCreateNewRequest?: boolean;
  sourceTag: insurance.InsuranceSourceTag;
};

export const useProvideInsurance = ({
  shouldCreateNewRequest = true,
  sourceTag,
}: ProvideInsuranceProps) => {
  const {
    state: { insurancePolicyReference: insurancePolicyID },
    dispatch,
  } = useContext(InsuranceContext);
  const {
    state: { application },
  } = useContext(LoanApplicationContext);
  const createInsuranceRequest = useCreateInsuranceRequest();
  const fetchInsuranceRequest = useInsuranceRequest(
    createInsuranceRequest.data?.ID
  );

  const source = useInsuranceSourceTag() ?? sourceTag;

  useEffect(() => {
    dispatch({
      type: 'Set source',
      payload: source as insurance.InsuranceSourceTag,
    });
  }, [source, dispatch]);

  useEventResource(KnownResourceType.LoanApplication, application?.ID);
  useEventResource(
    KnownResourceType.InsurancePolicy,
    insurancePolicyID?.PolicyID
  );

  useEffect(() => {
    const PolicyID = fetchInsuranceRequest.data?.PolicyID;
    if (
      fetchInsuranceRequest.data?.Status ===
      insurance_request.Status.Approved &&
      PolicyID
    ) {
      dispatch({
        type: 'Set insurance',
        scope: { parentType: 'insurance' },
        payload: fetchInsuranceRequest.data,
      });
    }
  }, [fetchInsuranceRequest.data, dispatch]);

  const { mutate: doCreate } = createInsuranceRequest;

  useEffect(() => {
    if (
      application?.PersonID &&
      application?.MarketCountry &&
      application?.CustomerID &&
      !insurancePolicyID &&
      shouldCreateNewRequest
    ) {
      doCreate({
        CustomerID: application?.CustomerID,
        PersonID: application.PersonID,
        MarketCountry: application.MarketCountry,
        LoanApplicationID: application.ID,
        Source: source as string,
      });
    }
  }, [
    application,
    insurancePolicyID,
    doCreate,
    shouldCreateNewRequest,
    source,
  ]);

  const isNew =
    fetchInsuranceRequest.data?.Status === insurance_request.Status.New;
  const TIMEOUT_MS = 60_000; // 1 minute
  const isOld = fetchInsuranceRequest.data
    ? +new Date() - +new Date(fetchInsuranceRequest.data?.CreatedAt) >
      TIMEOUT_MS
    : false; // 30 seconds
  const hasTimedOut = isNew && isOld;

  return {
    createInsuranceRequest,
    fetchInsuranceRequest,
    hasTimedOut,
  };
};
